var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "add-new-investor-form-success"
  }, [_c('div', {
    staticClass: "center-modal"
  }, [_c('div', {
    staticClass: "content content--top"
  }, [_c('sygni-container-title', {
    staticClass: "green"
  }, [_vm._v(" Add new investor ")])], 1), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "content__ok-image"
  }, [_c('sygni-circle-status-button', {
    attrs: {
      "status": _vm.statues.active
    }
  })], 1), _c('div', {
    staticClass: "content__title title"
  }, [_vm._v(" Investor was added ")]), _c('div', {
    staticClass: "content__description description"
  }, [_vm._v(" You can find it on your investors list ")]), _c('div', {
    staticClass: "content__buttons"
  }, [_c('sygni-rounded-button', {
    staticClass: "filled green",
    on: {
      "click": _vm.addProductToInvestor
    }
  }, [_vm._v(" Add product to this investor ")]), _c('sygni-rounded-button', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "outline disabled"
  }, [_vm._v(" AML check ")])], 1), _c('div', {
    staticClass: "content__link",
    on: {
      "click": function click($event) {
        return _vm.showNewInvestorNotification();
      }
    }
  }, [_vm._v(" Go to investors list ")])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }