




































import Vue from 'vue'
import Component from 'vue-class-component'
import {Statues} from "@/shared/interfaces/Statues";
import SygniSelect from "@/components/inputs/SygniSelect.vue";
import SygniRoundedButton from "@/components/buttons/SygniRoundedButton.vue";
import SygniTextArea from "@/components/inputs/SygniTextArea.vue";
import SygniCheckbox from "@/components/inputs/SygniCheckbox.vue";
import SygniCircleStatusButton from "@/components/buttons/SygniCircleStatusButton.vue";
import SygniInput from "@/components/inputs/SygniInput.vue";
import ExpandCollapse from "@/components/animations/ExpandCollapse.vue";
import SygniContainerTitle from "@/components/layout/SygniContainerTitle.vue";
import {mapState} from "vuex";
import {Investor} from "@/modules/genprox/modules/fund/modules/fundraising/store/types";

@Component({
  components: {
    SygniCircleStatusButton,
    ExpandCollapse,
    SygniRoundedButton, SygniTextArea, SygniCheckbox, SygniSelect, SygniInput, SygniContainerTitle},
  computed: {
    ...mapState('investors', {
      newInvestor: (state: any) => state.newInvestmentClient
    }),
  }
})
export default class AddNewInvestorFormSuccess extends Vue {
  readonly statues = Statues;
  newInvestor!: Investor;

  addProductToInvestor(): void {
      this.$store.commit('investors/clearAnnexingData');
      this.$store.commit('investors/setNewProductInvestor', this.newInvestor.id);
      if(this.$route.path.includes('company')) {
        this.$router.push('/company/fundraising/product/new/step-one');
      } else {
        this.$router.push('/fund/fundraising/product/new/step-one');
      }
  }

  showNewInvestorNotification(): void {
    if(this.$route.path.includes('company')) {
      this.$router.push('/company/fundraising');
    } else {
      this.$router.push('/fund/fundraising');
    }
  }
}
